import { BalanceItem, CelerBalance } from '@/state/reducers/balanceSlice';
import { getAssetName } from '@/utils/symbolMapping';
import BigNumber from 'bignumber.js';

export const aggregateBalances = (balances: Record<string, Record<string, BalanceItem>>) => {
    const aggregatedBalances = {} as Record<string, BalanceItem>;
    const allBalanceItems = Object.values(balances).flatMap(Object.values);

    for (const balanceItem of allBalanceItems) {
        const { currency, notionalCurrency, netPosition, notionalNetPosition } = balanceItem;
        if (!(currency in aggregatedBalances)) {
            aggregatedBalances[currency] = {
                currency,
                netPosition: 0,
                notionalNetPosition: 0,
                notionalCurrency,
                settlementDate: 'AGGR'
            };
        }

        aggregatedBalances[currency].netPosition = BigNumber(aggregatedBalances[currency].netPosition)
            .plus(netPosition)
            .toNumber();
        aggregatedBalances[currency].notionalNetPosition = BigNumber(aggregatedBalances[currency].netPosition)
            .plus(notionalNetPosition)
            .toNumber();
    }

    const balanceList = Object.values(aggregatedBalances);
    balanceList.sort((a: any, b: any) => (a.notionalNetPosition > b.notionalNetPosition ? -1 : 1));

    return balanceList;
};

export const sortBalances = (aggregatedBalances: BalanceItem[]) => {
    const temp: BalanceItem[] = [...aggregatedBalances].map((balance) => ({
        ...balance,
        currency: getAssetName(balance.currency)
    }));
    temp.sort(balanceSort);
    return temp;
};

export const balanceSort = (a: CelerBalance, b: CelerBalance) => {
    if (a.currency < b.currency) return -1;
    if (a.currency > b.currency) return 1;
    return 0;
};
