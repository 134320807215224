import CurrencyIcon from '@/components/common/CurrencyIcon';
import { numberFormatterConfig } from '@/config/config';
import { sortBalances } from '@/helpers/celerBalanceHelper';
import { shouldUseNopBalance } from '@/helpers/environmentHelper';
import { useAppSelector } from '@/state/hooks';
import { selectAggregatedBalance, selectNopBalance } from '@/state/reducers/balanceSlice';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { useMemo } from 'react';
import { useNumberFormatter } from 'react-aria';
import { useFormContext } from 'react-hook-form';
import { MdSwapHoriz } from 'react-icons/md';

const ConvertConfirmationTab = ({ conversionProps }: any) => {
    const { pair, type, estimatedProceeds } = conversionProps;

    const { watch } = useFormContext();
    const [convertFrom, convertTo, amount] = watch(['convertFrom', 'convertTo', 'amount']);
    const nopBalance = useAppSelector(selectNopBalance);
    const celerBalance = useAppSelector(selectAggregatedBalance).filter((b) => b.netPosition != 0);
    const sortedCelerBalances = useMemo(() => sortBalances(celerBalance), [celerBalance]);
    const bidAsk = useAppSelector((state) => state.marketPair.bidAsk[pair]);
    const numberFormatter = useNumberFormatter(numberFormatterConfig);

    const convertFromBalance = useMemo(() => {
        if (shouldUseNopBalance) {
            return nopBalance?.balances.find((b) => b.currency === convertFrom.value)?.totalAmount || 0;
        } else {
            return sortedCelerBalances.find((b) => b.currency === convertFrom.value)?.netPosition || 0;
        }
    }, [nopBalance, sortedCelerBalances, convertFrom.value]);

    const convertToBalance = useMemo(() => {
        if (shouldUseNopBalance) {
            return nopBalance?.balances.find((b) => b.currency === convertTo.value)?.totalAmount || 0;
        } else {
            return sortedCelerBalances.find((b) => b.currency === convertTo.value)?.netPosition || 0;
        }
    }, [sortedCelerBalances, convertTo.value]);

    const [convertFromCcy, convertToCcy] = useMemo(() => pair.split('/'), [pair]);

    const balancesAfterConversion = useMemo(
        () => ({
            from: BigNumber(convertFromBalance).minus(amount).toNumber(),
            to: BigNumber(convertToBalance).plus(estimatedProceeds).toNumber()
        }),
        [estimatedProceeds]
    );

    return (
        <div className="py-2 h-auto space-y-4">
            <div>
                <span className="text-neutral-400 text-xs">From</span>
                <div className="flex items-center justify-between w-full rounded-md bg-brand-background-dark border border-neutral-700 py-2 px-3 text-left text-sm">
                    <div className="flex items-center gap-2">
                        <CurrencyIcon ccy={convertFrom?.ccy || ''} />
                        <div className="flex flex-col">
                            <span className="block truncate text-sm">{convertFrom?.label || 'N/A'}</span>
                            <span className="text-neutral-400 text-2xs sm:text-xs leading-normal">
                                {convertFrom?.value || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col items-end justify-end">
                        <span className="font-bold text-neutral-200">{amount}</span>
                        <span className="text-2xs text-neutral-400 leading-none">
                            You have{' '}
                            <u>
                                {convertFromBalance} {convertFrom.value}
                            </u>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <span className="text-neutral-400 text-xs">To</span>
                <div className="flex items-center justify-between w-full rounded-md bg-brand-background-dark border border-neutral-700 py-2 px-3 text-left text-sm">
                    <div className="flex items-center gap-2">
                        <CurrencyIcon ccy={convertTo?.ccy || ''} />
                        <div className="flex flex-col">
                            <span className="block truncate text-sm">{convertTo?.label || 'N/A'}</span>
                            <span className="text-neutral-400 text-2xs sm:text-xs leading-normal">
                                {convertFrom?.value || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col items-end justify-end">
                        <span className="font-bold text-neutral-200">
                            {estimatedProceeds ? numberFormatter.format(estimatedProceeds) : 0}
                        </span>
                        <span className="text-2xs text-neutral-400 leading-none">Estimated Proceeds</span>
                    </div>
                </div>
            </div>
            <div>
                <span className="text-neutral-400 text-xs">Conversion Rate</span>
                <div className="flex w-full items-center justify-between rounded-md bg-brand-background-dark border border-neutral-700 py-2 px-3">
                    <span className="text-neutral-400 text-sm tracking-wider">
                        1 {convertFromCcy} = {numberFormatter.format(bidAsk[type])} {convertToCcy}
                    </span>
                    <span className="flex justify-center gap-2">
                        <CurrencyIcon ccy={convertFrom.ccy} />
                        <MdSwapHoriz className="h-6 w-6" />
                        <CurrencyIcon ccy={convertTo.ccy} />
                    </span>
                </div>
            </div>
            <div>
                <span className="text-neutral-400 text-xs">Estimated Balances after Conversion</span>
                <div className="flex flex-col w-full items-center justify-between rounded-md bg-brand-background-dark border border-neutral-700 py-2 px-3 gap-2">
                    <Balance convertTo={convertFrom} balance={balancesAfterConversion.from} />
                    <Balance convertTo={convertTo} balance={balancesAfterConversion.to} />
                </div>
            </div>
        </div>
    );
};

export default ConvertConfirmationTab;

const Balance = ({ convertTo, balance }: any) => {
    const numberFormatter = useNumberFormatter(numberFormatterConfig);
    const balanceColor = useMemo(() => {
        if (balance === 0) return 'text-neutral-200';
        else if (balance > 0) return 'text-brand-primary';
        else return 'text-brand-red';
    }, [balance]);

    return (
        <div className="flex w-full gap-3 text-xs items-center sm:text-sm">
            <div className="flex flex-[2_1_0] items-center gap-2">
                <CurrencyIcon ccy={convertTo?.ccy || ''} />
                <div className="flex flex-col">
                    <span className="block truncate text-sm">{convertTo?.label || 'N/A'}</span>
                    <span className="text-neutral-400 text-2xs sm:text-xs leading-normal">
                        {convertTo?.value || 'N/A'}
                    </span>
                </div>
            </div>
            <div className={cn('flex flex-[1_1_0] flex-col text-end font-bold', { [balanceColor]: true })}>
                {numberFormatter.format(balance)}
            </div>
        </div>
    );
};
