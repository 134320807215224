import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';

import { Button } from '@/components/common/Button';
import Divider from '@/components/common/Divider';
import {
    CryptoWithdrawalFormInput,
    CryptoWithdrawalFormValues,
    cryptoWithdrawalSchema
} from '@/components/form/schema/cryptoWithdrawalSchema';
import FilterSelect from '@/components/inputs/FilterSelect';
import Input from '@/components/inputs/Input';
import InputController from '@/components/inputs/InputController';
import Select from '@/components/inputs/Select';
import WithdrawalConfirmationModal from '@/components/modal/WithdrawalConfirmationModal';
import currencyConfig from '@/config/currency';
import { collapseBalances } from '@/helpers/balanceHelper';
import { aggregateBalances } from '@/helpers/celerBalanceHelper';
import { getEnvironment } from '@/helpers/environmentHelper';
import { getUserAccountBalances } from '@/services/BalanceService';
import { requestCryptoWithdrawal } from '@/services/EmailService';
import { fetchLatestBalance } from '@/services/UserService';
import { useAppSelector } from '@/state/hooks';
import { User, selectCredentials } from '@/state/reducers/authSlice';
import { BalanceItem, CollapsedBalances } from '@/state/reducers/balanceSlice';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import useToast from '@/utils/hooks/useToast';
import { Tab } from '@headlessui/react';
import { AxiosError } from 'axios';
import { ModalProps } from '../Modal';

const disclaimer = window.config?.modules?.withdrawal?.crypto?.disclaimer;

// referred from balanceSlice, TODO: Refactor this
export async function getLatestAccountBalance(credentials: User, currentAccount: string, callback: (balances) => void) {
    const isNewBalances = ['local', 'uat', 'prod'].includes(getEnvironment());
    const balanceUrl = window.config.balances?.rest;

    if (isNewBalances && balanceUrl) {
        const res = await getUserAccountBalances(credentials, currentAccount);
        const balances = collapseBalances(res.data.balances);
        callback(balances);
    } else {
        const balanceItems = await fetchLatestBalance(credentials, currentAccount);
        const balance = {} as Record<string, Record<string, BalanceItem>>;
        for (const balanceItem of balanceItems) {
            const { currency, settlementDate } = balanceItem;
            if (!balance[currency]) balance[currency] = { [settlementDate]: balanceItem };
            balance[currency][settlementDate] = balanceItem;
        }
        const aggregatedBalances = aggregateBalances(balance);
        callback(aggregatedBalances);
    }
}

export const defaultAccount = { label: 'Select an account', value: undefined };
export const defaultAsset = { label: 'Select an asset', value: undefined };

const defaultValues: Partial<CryptoWithdrawalFormInput> = {
    account: defaultAccount,
    amount: undefined,
    asset: defaultAsset,
    address: '',
    email: undefined
};

const cryptoAssets = [
    defaultAsset,
    ...currencyConfig
        .filter((ccy) => ccy.type === 'Crypto' && ccy.allowDepositWithdrawal)
        .map((ccy) => ({
            label: ccy.show,
            value: ccy.show
        }))
];

const CryptoWithdrawalTab = (props: ModalProps) => {
    const { opened, handlers } = props;

    const credentials = useAppSelector(selectCredentials);
    const confirmation = useDisclosure(false);
    const [toastError, toastSuccess] = useToast();

    const [balances, setBalances] = useState<CollapsedBalances[]>();

    const form = useForm<CryptoWithdrawalFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(cryptoWithdrawalSchema(balances))
    });

    const {
        reset,
        watch,
        trigger,
        handleSubmit,
        formState: { isValid, isSubmitting }
    } = form;

    const [account, asset] = watch(['account', 'asset']);

    const onSubmit = async (data: CryptoWithdrawalFormInput, e) => {
        const dataValidated = data as CryptoWithdrawalFormValues;
        try {
            if (credentials) {
                await requestCryptoWithdrawal(credentials, dataValidated);
                const title = `Crypto Withdrawal Request`;
                const body = `Your request to withdraw ${dataValidated.asset.label} ${dataValidated.amount} has been submitted`;
                toastSuccess({ body, title });
            }
        } catch (err: any) {
            const { response } = err as AxiosError<string>;
            const title = response?.statusText;
            const body = response?.data;
            toastError({ body, title, persist: true });
        }
    };

    const onError = (e: FieldErrors) => console.log(e);

    const accounts = useMemo(() => {
        const temp = credentials?.accounts?.map((account) => ({
            label: account.code,
            value: account.code
        }));
        if (temp) return [defaultAccount, ...temp];
        return [defaultAccount];
    }, [credentials]);

    useEffect(() => {
        if (account?.value && credentials) {
            getLatestAccountBalance(credentials, account.value, (balances) => setBalances(balances));
        } else {
            setBalances(undefined);
        }
    }, [account, credentials]);

    useEffect(() => {
        if (opened) {
            reset(defaultValues);
        }
    }, [opened]);

    return (
        <Tab.Panel className="h-full">
            <FormProvider {...form}>
                <form className="h-full flex flex-col lg:block" onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="flex-1 basis-0 lg:flex-auto p-2 py-3 sm:p-4 h-auto lg:h-[450px] overflow-y-scroll">
                        <div className="flex flex-col gap-4">
                            <div className="space-y-3 sm:space-y-2">
                                <h3 className="font-bold">Withdrawal Details</h3>
                                <InputController
                                    name="account"
                                    label="Account To Withdraw"
                                    onChangeOverride={() => {
                                        if (account?.value && asset?.value) trigger('amount');
                                    }}
                                    required>
                                    <Select options={accounts || []} />
                                </InputController>
                                <InputController
                                    name="asset"
                                    label="Withdrawal Asset"
                                    onChangeOverride={() => {
                                        if (account?.value && asset?.value) trigger('amount');
                                    }}
                                    required>
                                    <FilterSelect options={cryptoAssets} />
                                </InputController>
                                <InputController
                                    name="amount"
                                    label="Withdrawal Amount"
                                    placeholder="Withdrawal Amount"
                                    disabled={!(account?.value && asset?.value)}
                                    required>
                                    <Input />
                                </InputController>
                                <InputController
                                    name="address"
                                    label="Wallet Address"
                                    placeholder="Wallet Address"
                                    required>
                                    <Input />
                                </InputController>
                            </div>
                            <div className="space-y-3 sm:space-y-2">
                                <h3 className="font-bold">Email Details (optional)</h3>
                                <InputController name="email.cc_to" label="CC To" placeholder="CC To">
                                    <Input />
                                </InputController>
                            </div>
                            {disclaimer && (
                                <div className="space-y-3 sm:space-y-2">
                                    <h3 className="font-bold">Terms & Conditions</h3>
                                    <div className="text-sm text-neutral-400 text-justify">{disclaimer}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="flex flex-row justify-between text-neutral-200 text-sm items-center p-2 sm:p-4">
                        <div className="flex justify-center w-full">
                            <Button
                                type="button"
                                onClick={confirmation[1].open}
                                isLoading={isSubmitting}
                                disabled={!isValid || isSubmitting}>
                                Send Withdrawal Request
                            </Button>
                        </div>
                    </div>
                    <WithdrawalConfirmationModal
                        type="Crypto"
                        opened={confirmation[0]}
                        handlers={confirmation[1]}
                        user={credentials?.username}
                        keys={Object.keys(defaultValues) as Array<keyof CryptoWithdrawalFormValues>}
                        manualOnSubmit={handleSubmit(onSubmit, onError)}
                    />
                </form>
            </FormProvider>
        </Tab.Panel>
    );
};

export default CryptoWithdrawalTab;
